$(function() {
    $('[data-input-phone]').mask('+7(999)999-99-99');
    $.validator.addMethod(
      "regex",
      function(value, element, regexp) {
        if (regexp.constructor != RegExp)
          regexp = new RegExp(regexp);
        else if (regexp.global)
          regexp.lastIndex = 0;
        return this.optional(element) || regexp.test(value);
      },
      "Please check your input."
    );
    var form = $('.form');
    form.each(function() {
      $(this).attr('novalidate', '');
      $(this).validate({
        rules: {
          'name': {
            required: true,
            regex: /^[А-ЯЁа-яё\s-]*$/
          },
          'phone': {
            required: true,
            regex: /^\+7\(\d{3}\)\d{3}\-\d{2}\-\d{2}$/,
            rangelength: [16, 16]
          },
          'email': {
            required: true,
            email: true
          },
          'name-company': {
            required: true
          },
          'subject': {
            maxlength: 120
          }
        },
        messages: {
          'name': {
            required: 'Это поле обязательно для заполнения',
            regex: 'Допустимы только русские буквы, пробел и дефис'
          },
          'phone': {
            required: 'Это поле обязательно для заполнения',
            regex: 'Введите номер телефона в формате +7(000)000-00-00'
          },
          'email': {
            required: 'Это поле обязательно для заполнения',
            email: 'Введен неверный email'
          },
          'name-company': {
            required: 'Это поле обязательно для заполнения'
          },
          'subject': {
            maxlength: 'Превышено допустимое количество символов'
          }
        }
      });
    });

  var messages = {
    success: {
      id: 'success-message',
      icon: '/images/icons/success.svg',
      title: 'Сообщение успешно отправлено!',
      text: 'Ваше сообщение успешно отправлено.<br> Наш менеджер свяжется с вами в ближайшее время!'
    },
    request: {
      id: 'success-request',
      icon: '/images/icons/success.svg',
      title: 'Заявка успешно оформлена!',
      text: 'Ваша заявка успешно офрмлена.<br> Наш менеджер свяжется с Вами с 10:00 до 18:00 по будним дням.'
    },
    fail: {
      id: 'fail-message',
      icon: '/images/icons/error.svg',
      title: 'Произошла ошибка!',
      text: 'Приносим свои извинения за возможные неудобства.<br> Попробуйте обновить страницу и заполнить форму еще раз или воспользуйтесь контактной информацией чтобы связаться с нами.'
    }
  }

  function createMessage(options) {
    return '<div class="form-message"><div class="form-message__icon"><img src="'+ options.icon +'" alt"'+ options.title +'"></div><h3 class="form-message__title">'+ options.title +'</h3><p class="form-message__text">' + options.text + '</p></div>'
  }

  function popupMessage(form, options) {
    var message = createMessage(options);
    var popup = '<div style="display: none;" id="message-popup"><div class="popup" id="' + options.id + '"><div class="popup__close arcticmodal-close"></div><div class="popup__box">' + message + '</div></div></div>';
    $(form).after(popup);
    $('#' + options.id).arcticmodal({
      afterClose: function() {
        $('#message-popup').remove();
      }
    });
  }

  function appendMessage(form, options) {
    var message = createMessage(options);
    var appendMessage = '<div id="' + options.id + '" style="display: none;">'+ message +'</div>';
    var formGroup = form.closest('[data-form]');
    formGroup.after(appendMessage);
    formGroup.slideUp();
    $('#' + options.id).slideDown();
    setTimeout(function() {
      $('#' + options.id).remove();
      formGroup.slideDown();
    }, 5000);
  }

  function clearFields(form) {
    form.find('input.input:not([data-no-reset])').val('');
    form.find('textarea.input').val('');
  };

  // send form
  $('form').submit(function(e) {
    e.preventDefault();
    var $form = $(this);
    var isValid = $(this).valid();
    $form.find('.btn').prop( "disabled", true );

    if (isValid) {
      $.ajax({
        type: $form.attr('method'),
        url: $form.attr('action'),
        data: $form.serialize()
      }).done(function() {
        clearFields($form);
        if ($form.hasClass('form_popup')) {
          appendMessage($form, messages.success);
        } else {
          if ($form.hasClass('form_reguest')) {
            popupMessage($form, messages.reguest);
          } else {
            popupMessage($form, messages.success);
          }
        }
        $form.find('.btn').prop( "disabled", false);
      }).fail(function() {
        clearFields($form);
        if ($form.hasClass('form_popup')) {
          appendMessage($form, messages.fail);
        } else {
          popupMessage($form, messages.fail);
        }
        $form.find('.btn').prop( "disabled", false);
      });
    }
  });
});